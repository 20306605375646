<template>
  <div>
    <h3 class="label">{{ label }}</h3>
    <Dropdown
      :id="'filter-dropdown-' + id"
      :key="label"
      dropdown-class="pt-[46px] rounded shadow-purple-600 border border-purple-600"
    >
      <template #toggle="{ onClick, isActive, toggleAttributes }">
        <button
          v-bind="toggleAttributes"
          class="w-full text-left font-medium border h-[46px] px-10 lg:px-15 rounded flex items-center outline-none leading-tight disabled:text-gray-300 disabled:pointer-events-none overflow-hidden min-w-0"
          :disabled="disabled"
          :aria-label="label"
          :class="
            isActive
              ? 'border-transparent bg-transparent focus:text-purple-800'
              : 'bg-white hover:border-blue-500 focus:shadow-purple-600 focus:border-purple-600'
          "
          @click="onButtonClick(onClick)"
        >
          <div class="flex-1 relative h-full">
            <span
              class="truncate absolute left-0 top-1/2 -translate-y-1/2 w-full text-sm md:text-base"
              :class="{ 'text-gray-500': !selectedIds.length }"
              >{{ buttonTitle }}</span
            >
          </div>
          <span
            v-if="selectedIds.length && multiple"
            class="size-20 rounded-full bg-purple-500 text-white text-sm text-center ml-5 mr-8"
            aria-hidden="true"
            >{{ selectedIds.length }}</span
          >
          <SpriteSymbol
            name="caret"
            class="w-10 h-6 transition-all duration-250 ml-auto text-blue-700"
            :class="{ '-scale-y-100': isActive }"
            aria-hidden="true"
          />
        </button>
      </template>
      <template #default="{ isActive }">
        <KeyboardNavigationProvider
          v-if="isActive"
          class="py-5 max-h-[370px] overflow-auto overscroll-contain border-t border-t-gray-200"
          :is-enabled="isActive"
        >
          <div v-if="search" class="p-15">
            <label for="search-filters">
              <span class="sr-only">{{ termSearchPlaceholder }}</span>
              <input
                id="search-filters"
                ref="inputEl"
                v-model="searchText"
                type="text"
                class="input w-full"
                :placeholder="termSearchPlaceholder"
              />
            </label>
          </div>
          <FilterTerm
            v-for="term in terms"
            :key="term.value"
            :title="term.label"
            :is-selected="selectedIds.includes(term.value)"
            :count="term.count"
            :href="getTermLink(term.value)"
            :multiple="!!multiple"
          />
        </KeyboardNavigationProvider>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import type { SearchFacetTerm } from '~/layers/site-search/helpers/types'

const props = defineProps<{
  id: string
  label: string
  terms?: SearchFacetTerm[]
  totalTerms?: number
  multiple?: boolean
  search?: boolean
  searchPlaceholder?: string
}>()

const route = useRoute()
const router = useRouter()
const { $texts } = useNuxtApp()

const inputEl = ref<HTMLInputElement | null>(null)

const termSearchPlaceholder = computed(
  () =>
    props.searchPlaceholder ||
    $texts('search.termSearchPlaceholder', 'Filter durchsuchen'),
)

const searchText = defineModel<string>()

const disabled = computed(
  () => !props.search && !props.terms?.find((v) => v.count),
)

const selectedFromQuery = useQueryString(props.id)

const selectedIds = computed(() =>
  selectedFromQuery.value.split(':').filter(Boolean),
)

const selectedTerms = computed(() =>
  (props.terms?.filter((v) => selectedIds.value.includes(v.value)) || []).sort(
    (a, b) => {
      return (
        selectedIds.value.indexOf(a.value) - selectedIds.value.indexOf(b.value)
      )
    },
  ),
)

function getTermLink(id: string): string {
  const selected = selectedIds.value.includes(id)
  const newIds = selected
    ? selectedIds.value.filter((v) => v !== id)
    : props.multiple
      ? [...selectedIds.value, id]
      : [id]

  return router.resolve({
    query: {
      ...route.query,
      [props.id]: newIds.length ? newIds.join(':') : undefined,
      page: undefined,
    },
  }).href
}

function onButtonClick(toggleDropdown: () => void) {
  toggleDropdown()
  nextTick(() => {
    if (inputEl.value) {
      inputEl.value.focus()
    }
  })
}

const buttonTitle = computed(() => {
  if (!selectedIds.value.length || !props.terms) {
    return $texts('selectFilter', 'Filter auswählen')
  }

  return selectedTerms.value.map((v) => v.label).join(', ')
})

defineOptions({
  name: 'SearchFacet',
})
</script>
