<template>
  <div class="grid grid-cols-2 gap-15">
    <label for="dateFrom" class="w-full min-w-0">
      <span class="label">
        {{ $texts('search.dateFrom', 'Datum von') }}
      </span>
      <input
        id="dateFrom"
        v-model.lazy="dateFromInput"
        type="date"
        :min="min"
        :max="max"
        class="input w-full"
      />
    </label>

    <label for="dateTo" class="w-full min-w-0 lg:min-w-[200px]">
      <span class="label">
        {{ $texts('search.dateTo', 'bis') }}
      </span>
      <input
        id="dateTo"
        v-model.lazy="dateToInput"
        type="date"
        :min="min"
        :max="max"
        class="input w-full"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id: string
  min: string
  max: string
}>()

const keyMin = computed(() => props.id + ':min')
const keyMax = computed(() => props.id + ':max')

const dateFrom = useQueryString(keyMin)
const dateTo = useQueryString(keyMax)

const dateFromInput = computed({
  get() {
    return dateFrom.value || props.min
  },
  set(v: string) {
    if (v === props.min) {
      dateFrom.value = ''
    } else {
      dateFrom.value = v
    }
  },
})

const dateToInput = computed({
  get() {
    return dateTo.value || props.max
  },
  set(v: string) {
    if (v === props.max) {
      dateTo.value = ''
    } else {
      dateTo.value = v
    }
  },
})
</script>
