<template>
  <a
    :href="href"
    class="flex px-10 md:px-15 py-5 md:py-6 gap-5 hover:text-blue-700 group outline-none text-sm text-left hover:bg-gray-20"
    :class="{
      'pointer-events-none opacity-25': !href,
      'focus:bg-gray-100': !multiple,
      'bg-blue-800 text-white hover:text-white hover:!bg-blue-700 focus:!bg-blue-700':
        !multiple && isSelected,
    }"
    rel="nofollow"
    @click.prevent="onClick"
  >
    <span
      v-if="multiple"
      aria-hidden
      class="size-20 border border-blue-800 flex items-center justify-center shrink-0 group-focus:shadow-purple-600 group-focus:border-purple-600"
      :class="[
        isSelected
          ? 'bg-blue-900 border-blue-900'
          : 'group-hover:bg-blue-50 group-hover:border-blue-500 group-focus:bg-purple-50',
        multiple ? 'rounded' : 'rounded-full',
      ]"
    >
      <SpriteSymbol
        v-if="isSelected"
        name="check"
        class="w-[12px] h-[9px] text-white"
        aria-hidden="true"
      />
    </span>
    <span>{{ title }}</span>
    <span
      v-if="count > 0"
      class="text-gray-600 ml-auto"
      :class="{
        'text-white/80': !multiple && isSelected,
      }"
      >{{ count }}</span
    >
  </a>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string
  href: string
  count: number
  isSelected: boolean
  multiple: boolean
}>()

const router = useRouter()
const { polite } = useRouteAnnouncer()
const { $texts } = useEasyTexts()

async function onClick() {
  await router.push(props.href)

  // Announce the change to the filter.
  const message = (
    props.isSelected
      ? $texts('search.filterDeselected', 'Filter entfernt: @term')
      : $texts('search.filterSelected', 'Filter hinzugefügt: @term')
  ).replace('@term', props.title)
  polite(message)
}
</script>
