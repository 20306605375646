<template>
  <label for="dateTo" class="flex-1">
    <div class="label">{{ title }}</div>
    <input
      id="dateTo"
      v-model.lazy="dateInput"
      type="date"
      :min="min"
      :max="max"
      class="input w-full"
    />
  </label>
</template>

<script setup lang="ts">
const props = defineProps<{
  id: string
  title: string
  min: string
  max: string
}>()

const keyMax = computed(() => props.id + ':min')

const date = useQueryString(keyMax)

const dateInput = computed({
  get() {
    return date.value || props.min
  },
  set(v: string) {
    if (v === props.min) {
      date.value = ''
    } else {
      date.value = v
    }
  },
})
</script>
