<template>
  <div
    class="flex justify-between items-center mt-30 mb-40 text-lg min-h-30 flex-wrap gap-10"
  >
    <h3 class="font-bold flex gap-5 items-center min-h-[32px]">
      <SpriteSymbol
        v-if="!total"
        name="circle-warning"
        class="size-20 text-red-800"
        aria-hidden="true"
      />
      <span>{{ titleMapped }}</span>
    </h3>
    <a
      v-if="resetText"
      class="button is-sm is-close"
      :href="route.path"
      @click.prevent="onReset"
    >
      {{ resetText }}
    </a>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  filterCount?: number
  total: number | undefined
  totalDocuments?: number | null
  searchTerm?: string
  titleSingular?: string
  titlePlural?: string
}>()

const route = useRoute()
const router = useRouter()
const { $texts } = useEasyTexts()
const { polite } = useRouteAnnouncer()

const titles = computed(() => {
  return {
    singular: props.titleSingular || $texts('resultSingular', 'Resultat'),
    plural: props.titlePlural || $texts('resultPlural', 'Resultate'),
  }
})

function getResultTypeTitle(count = 0) {
  if (count === 0 || count > 1) {
    return titles.value.plural
  }
  return titles.value.singular
}

const renderedTotal = computed(() => {
  if (!props.filterCount && !props.searchTerm && props.totalDocuments) {
    return props.totalDocuments
  }

  return props.total || 0
})

const title = computed(() => {
  if (!props.total) {
    return $texts('search.titleNoResults', 'Keine @resultType gefunden')
  }

  // ES stops counting after 10000 documents, so this is the max value.
  // If the actual total number of documents was manually provided,
  // we don't have to display the generic 'over 10k' text.
  if (props.total >= 10000 && !props.totalDocuments) {
    return $texts('search.titleTooManyResults', "Mehr als 10'000 @resultType")
  }

  if (props.searchTerm) {
    return $texts(
      'search.titleResultsWithTerm',
      '@count @resultType für den Suchbegriff «@term»',
    )
  }

  return '@count @resultType'
})

const titleMapped = computed(() => {
  return title.value
    .replace('@count', renderedTotal.value.toLocaleString('de-CH'))
    .replace('@term', props.searchTerm || '')
    .replace('@resultType', getResultTypeTitle(renderedTotal.value))
})

const resetText = computed(() => {
  if (!props.filterCount && props.searchTerm) {
    return $texts('filterRemoveSearchTerm', 'Suchbegriff entfernen')
  } else if (props.filterCount === 1) {
    return $texts('filterRemoveSingle', 'Filter entfernen')
  } else if (props.filterCount && props.filterCount > 1) {
    return $texts('filterRemoveAll', 'Alle Filter entfernen')
  }
  return ''
})

async function onReset() {
  await router.push(route.path)
  polite(
    $texts('accessibility.searchHasBeenReset', 'Die Suche wurde zurückgesetzt'),
  )
}
</script>
